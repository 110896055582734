import React, { useState, useEffect } from 'react';

import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import { styFormWrapper } from './styles';
import { useForm } from 'react-hook-form';

function WishesForm() {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [showPicker, setShowPicker] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const nameValue = watch('name');
  const wishesValue = watch('wishes');

  useEffect(() => {
    if (nameValue && wishesValue) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [nameValue, wishesValue]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showPicker && !event.target.closest('.emoji-mart')) {
        setShowPicker(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showPicker]);

  const addEmoji = (e) => {
    let sym = e.unified.split('-');
    let codesArray = [];
    sym.forEach((el) => codesArray.push('0x' + el));
    let emoji = String.fromCodePoint(...codesArray);
    setValue('wishes', wishesValue + emoji);
  };

  const onSubmit = (data) => {
    fetch(`https://api.vinhnhatwedding.com/api/v1/wish`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        content: data.wishes,
        name: data.name,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setSuccessMessage('Gửi lời chúc thành công');
        reset();
      });
  };
  return (
    <form className="form-wish" css={styFormWrapper} onSubmit={handleSubmit(onSubmit)}>
      <div className="form-confirm-wish">
        <label className="label-wish">GỬI LỜI CHÚC</label>
        <input className="name-wish" {...register('name')} placeholder="Nhập Họ Tên"></input>
        {/* <textarea className="wishes" {...register('wishes')} placeholder="Nhập lời chúc..."></textarea> */}
        <div style={{ position: 'relative' }}>
          <textarea className="wishes" {...register('wishes', { required: true })} placeholder="Nhập lời chúc..." />
          <button
            type="button"
            onClick={() => setShowPicker((val) => !val)}
            style={{
              position: 'absolute',
              right: '10px',
              bottom: '25px',
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
            }}
          >
            😊
          </button>
          {showPicker && (
            <div style={{ position: 'absolute', bottom: '40px', right: '10px' }}>
              <Picker onSelect={addEmoji} />
            </div>
          )}
        </div>
        <button className="btn btn-block btn-wishes" type="submit" disabled={isButtonDisabled}>
          Gửi
        </button>
        {successMessage && <div className="success-message">{successMessage}</div>}
      </div>
    </form>
  );
}

export default React.memo(WishesForm);
