import React, { useState, useEffect, useCallback } from 'react';

import WishesItem from './WishesItem';
import { wishlist } from './wishlist-data';
import { styButtonWrapper } from './styles';
import useWishesData from '../../hooks/useWishesData';

const INTERVAL_SLIDE = 8000;

function WishesContainer() {
  const [active, setActive] = useState(0);
  const [pauseSlide, setPauseSlide] = useState(false);
  const { wishesData, loading, error } = useWishesData();
  const totalWishes = wishesData.length || 0;
  const [startX, setStartX] = useState(0);
  const [endX, setEndX] = useState(0);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const handleSetActive = (isNext = true) => {
    if (isNext) {
      if (active === totalWishes - 1) {
        setActive(0);
      } else {
        setActive(active + 1);
      }
    } else {
      if (active === 0) {
        setActive(totalWishes - 1);
      } else {
        setActive(active - 1);
      }
    }

    setPauseSlide(true);

    setTimeout(() => {
      setPauseSlide(false);
    }, INTERVAL_SLIDE);
  };

  const handleSetNext = useCallback(() => {
    if (active === wishesData.length - 1) {
      setActive(0);
    } else {
      setActive(active + 1);
    }
  }, [active, wishesData.length]);

  const renderWishlist = () => {
    return wishesData.map((w, index) => <WishesItem key={index} {...w} isActive={index === active} />);
  };

  /** Side effect to autoscroll */
  useEffect(() => {
    const interval = setInterval(() => {
      if (!pauseSlide) {
        handleSetNext();
      } else {
        clearInterval(interval);
      }
    }, INTERVAL_SLIDE);

    return () => clearInterval(interval);
  }, [handleSetNext, pauseSlide]);

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setEndX(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (startX - endX > 50) {
      handleSetActive(true); // swipe left
    } else if (endX - startX > 50) {
      handleSetActive(false); // swipe right
    }
  };

  const handleMouseDown = (e) => {
    setIsMouseDown(true);
    setStartX(e.clientX);
  };

  const handleMouseMove = (e) => {
    if (isMouseDown) {
      setEndX(e.clientX);
    }
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
    if (startX - endX > 50) {
      handleSetActive(true); // swipe left
    } else if (endX - startX > 50) {
      handleSetActive(false); // swipe right
    }
  };
  return (
    <div
      className="wrap-testimony"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={() => setIsMouseDown(false)}
    >
      {renderWishlist()}
      {/* <div css={styButtonWrapper}>
        <button className="btn btn-sm button-nav" onClick={() => handleSetActive(false)}>{`< Sebelumnya`}</button>
        <button className="btn btn-sm button-nav" onClick={() => handleSetActive(true)}>{`Selanjutnya >`}</button>
      </div> */}
    </div>
  );
}

export default React.memo(WishesContainer);
