import { css, keyframes } from '@emotion/core';

const animation = keyframes`
  0% { right: -300px; opacity: 0;}
  50% { right: 50px; opacity: 0.5;}
  100% {right: 0px; opacity: 1;};
`;

const animationLeft = keyframes`
  0% { left: -300px; opacity: 0;}
  50% { left: 50px; opacity: 0.5;}
  100% {left: 0px; opacity: 1;};
`;

export const styWithAnimation = (isActive) => css`
  position: relative;
  animation: ${isActive ? animation : animationLeft} 1s;
`;

export const styWrapperItem = css`
  h4 {
    color: #828282;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0 0 4px 0;
  }

  blockquote {
    margin-top: 16px !important;
  }

  .infoName {
    font-size: 12px;
    text-transform: capitalize;
    letter-spacing: 2px;
    display: block;
  }

  .hide {
    display: none;
  }

  .active {
    diplay: block;
  }

  .description {
    font-size: 16px !important;
  }

  @media screen and (max-width: 400px) {
    .description {
      font-size: 14px !important;
    }
  }
`;

export const styButtonWrapper = css`
  display: flex;
  justify-content: center;

  .button-nav {
    font-size: 14px;
    padding: 5px 20px;
  }
`;

export const styFormWrapper = css`
  .form-wish {
    display: flex !important;
  }
  .form-confirm-wish {
    display: flex;
    flex-direction: column;
    border: 1px solid #f14e95;
    border-radius: 30px;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 400px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 375px) {
    .form-confirm-wish {
      width: 360px;
    }
  }
  @media screen and (min-width: 400px) and (max-width: 1200px) {
    .form-confirm-wish {
      width: 400px;
    }
  }
  .wishes {
    resize: vertical;
    margin-bottom: 15px;
    height: 100px;
    border-radius: 5px;
    border: none;
    border-bottom: 1px solid #f14e95;
    transition: border-bottom 0.3s;
    font-size: 15px;
    padding: 5px;
    width: 100%;
  }
  .name-wish {
    margin-bottom: 15px;
    border-radius: 5px;
    height: 40px;
    font-size: 15px;
    border: none;
    border-bottom: 1px solid #f14e95;
    transition: border-bottom 0.3s;
    padding: 5px;
  }
  .wishes:focus {
    outline: none;
  }
  .name-wish:focus {
    outline: none;
  }
  .btn-wishes {
    background-color: #f14e95;
    color: white;
  }
  .btn-wishes:hover {
    background-color: #d84686;
  }
  .label-wish {
    text-align: center;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 600 !important;
    color: #f14e95;
  }
  input:focus::placeholder,
  textarea:focus::placeholder {
    opacity: 0;
  }
  .success-message {
    margin-top: 10px;
    text-align: center;
    color: #272727;
  }
  .success-message::before {
    display: inline;
    content: '✔ ';
    color: #22bb33;
  }
`;
