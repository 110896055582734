import { css } from '@emotion/core';
import Background from '@assets/images/slide-6.jpg';

export const styWrapper = css`
  form {
    max-width: 500px;
    margin: 0 auto;
  }
  .confirmation {
    height: 300px;
  }
  .form {
    max-width: 200px;
    margin: 0 auto;
  }
  .label {
    font-weight: 100;
    color: white;
    text-align: center;
    padding-bottom: 10px;
    font-size: 15px;
    padding: 0 !important;
  }
  .error {
    color: #bf1650;
  }
  .error::before {
    display: inline;
    content: '⚠ ';
  }
  .error-message {
    text-align: center;
  }
  .error-message::before {
    display: inline;
    content: '⚠ ';
  }
  .input-code,
  .input-name {
    display: block;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    border: none;
    padding: 10px 15px;
    margin-bottom: 10px;
    font-size: 16px;
    text-align: center;
    outline: none;
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
  }

  .input-code::placeholder {
    color: white;
  }
  .input-code:focus::placeholder {
    opacity: 0;
  }

  background-image: url('https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/templates/6174eeb9b9d68813734ce3f5/83889209ba32d01ce7d5ba70c4e07cc6.jpg');
  background-size: cover;
  background-position: center;
  @media screen and (min-width: 371px) and (max-width: 680px) {
    background-position: 95% 25%;
    .fh5co-heading p {
      font-size: 15px;
    }
  }
  @media screen and (max-width: 370px) {
    background-position: 90% 25%;
    .fh5co-heading p {
      font-size: 14px;
    }
  }
  p {
    color: rgba(255, 255, 255, 0.8) !important;
  }
  @media screen and (min-width: 400px) and (max-width: 1000px) {
    .confirmation {
      width: 400px;
    }
  }

`;

export const styFlex = css`
  display: flex;
  justify-content: center;
`;
