import React, { Fragment, useState } from 'react';
import { GOOGLE_MAPS_LINK } from '@/constants';
import { styWrapper } from './styles';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function LocationSection() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const [images, setImages] = useState([
    'https://storage.googleapis.com/vinhnhatwedding/daisy/daisy_1.jpg',
    'https://storage.googleapis.com/vinhnhatwedding/daisy/daisy_2.jpg',
    'https://storage.googleapis.com/vinhnhatwedding/daisy/daisy_5.jpg',
    'https://storage.googleapis.com/vinhnhatwedding/daisy/daisy_7.jpg',
  ]);

  return (
    <Fragment>
      <div id="fh5co-couple-story" className="fh5co-section-gray" css={styWrapper}>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 text-center fh5co-heading animate-box">
              <h2 className="main-font">Location of the wedding ceremony</h2>
              <p className="sub-title">
                <a
                  href={GOOGLE_MAPS_LINK}
                  title="Click untuk melihat peta di Google Maps"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: '#828282' }}
                >
                  <strong>Maison De Charme - Restaurant & Events</strong>
                  <p>
                    <i className="info"> Daisy Hall - Tran Xuan Soan, District 7, HCMC</i>
                  </p>
                </a>
                <a
                  href={GOOGLE_MAPS_LINK}
                  title="Click to go to google map"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: '#828282' }}
                >
                  <p className="google-maps">
                    <i className="info-map">Google Maps</i>
                  </p>
                </a>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 col-md-offset-1">
              <Slider {...settings}>
                {images.map((image, index) => (
                  <div key={index}>
                    <img src={image} alt={`Slide ${index + 1}`} style={{ width: '100%' }} />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default React.memo(LocationSection);
