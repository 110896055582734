import React, { useState } from 'react';
import { string, bool } from 'prop-types';
import { Link } from 'gatsby';
import { useForm } from 'react-hook-form';

import { styWrapper, styFlex } from './styles';

function ConfirmationSection({ isInvitation, guestName }) {
  const [guestNameFromCode, setGuestNameFromCode] = useState('');
  const [guestCodeFromCode, setGuestCodeFromCode] = useState('');
  const [guestData, setGuestData] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onKeyPress = (event) => {
    const regex = /^[a-zA-Z0-9]*$/; // Chỉ cho phép các ký tự chữ cái và số
    if (!regex.test(event.key)) {
      event.preventDefault(); // Chặn ký tự không hợp lệ
    }
  };

  const handleGuestSubmit = (code) => {
    // Fetch guest data from the API
    fetch(`https://api.vinhnhatwedding.com/api/v1/guest/${code}`)
      .then((response) => response.json())
      .then((guest) => {
        if (guest.code === code) {
          if (guestData[code]) {
            setErrorMessage('Người tham dự đã xác nhận tham dự'); // Hiển thị thông báo lỗi
          } else {
            // Save guest information
            setGuestData({ ...guestData, [code]: guest.prefix_name + ' ' + guest.short_name });
            setGuestCodeFromCode(guest.code);
            setGuestNameFromCode(guest.prefix_name + ' ' + guest.short_name);
            setErrorMessage('');
          }
        } else {
          setErrorMessage('Không tìm thấy người tham dự');
          setGuestNameFromCode('');
        }
      })
      .catch((error) => console.error('Error fetching data:', error));
  };
  const handleCodeChange = (event) => {
    const code = event.target.value.trim().toUpperCase();
    if (guestData[code]) {
      setGuestNameFromCode(guestData[code]);
      setErrorMessage('');
    } else {
      handleGuestSubmit(code);
    }
  };
  return (
    <div id="fh5co-started" className="fh5co-bg" css={styWrapper}>
      <div className="overlay"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
            <h2 className="main-font">{` Confirm attendance?`}</h2>
            <p>
              Để tụi mình có sự chuẩn bị thật chu đáo cho bữa tiệc, mời bạn cùng xác nhận tham dự cùng dâu rể nhé.{' '}
              <br></br>
              Hãy tìm theo <b> Mã khách mời </b> trên thiệp cưới, và xác nhận thôi nào ...
            </p>
          </div>
        </div>
        <div className="row" css={styFlex}>
          <div className="col-md-8 confirmation">
            {/* <Link to={`e-ticket?${codeLink}`}>
              <button className="btn btn-default btn-block">Get Ticket</button>
            </Link> */}
            <form className="form" onSubmit={handleSubmit(handleGuestSubmit)}>
              <div>
                <input
                  className="input-code"
                  {...register('code', {
                    required: true,
                    maxLength: 6,
                    pattern: /^[a-zA-Z_0-9]+$/i,
                  })}
                  placeholder="Your Code"
                  onBlur={handleCodeChange}
                  onKeyPress={onKeyPress}
                  maxLength="6"
                />
                {errors?.code?.type === 'required' && <p className="error">This field is required</p>}
                {errors?.code?.type === 'maxLength' && <p className="error">Code cannot exceed 6 characters</p>}
                {errors?.code?.type === 'pattern' && (
                  <p className="error">Special characters cannot be entered in the code</p>
                )}
              </div>
              <div>
                <input
                  className="input-name"
                  {...register('name')}
                  placeholder="Tên khách mời"
                  value={guestNameFromCode}
                  readOnly
                />
                {errors?.lastName?.type === 'pattern' && <p>Alphabetical characters only</p>}
              </div>
              <div>
                {guestCodeFromCode ? (
                  <Link to={`e-ticket?code=${guestCodeFromCode}`}>
                    <button className="btn btn-default btn-block">Confirmation</button>
                  </Link>
                ) : (
                  <button className="btn btn-default btn-block" disabled>
                    Confirmation
                  </button>
                )}
              </div>
              {/* <label className="label">NAME</label> */}
            </form>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </div>
        </div>
      </div>
    </div>
  );
}

ConfirmationSection.propTypes = {
  isInvitation: bool.isRequired,
  guestName: string.isRequired,
};

export default React.memo(ConfirmationSection);
