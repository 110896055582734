import React from 'react';
import { string } from 'prop-types';

function WeddingInfoBox({ title, date, time, description, location, map }) {
  return (
    <div className="col-md-6 col-sm-6 col-xs-12 text-center event">
      <div className="event-wrap">
        <h3>{title}</h3>
        <div className="event-col">
          <i className="icon-clock"></i>
          <span>{time}</span>
        </div>
        <div className="event-col">
          <i className="icon-calendar"></i>
          <span>{date}</span>
        </div>
        <div className="event-col">
          <i className="icon-location-pin"></i>
          <a href={map} target="_blank" className="location">
            {location}
          </a>
        </div>
      </div>
    </div>
  );
}

WeddingInfoBox.propTypes = {
  title: string.isRequired,
  date: string.isRequired,
  time: string.isRequired,
  description: string.isRequired,
};

export default React.memo(WeddingInfoBox);
