import React, { useState, useEffect } from 'react';
import axios from 'axios';
const POLL_INTERVAL = 60000;
const useWishesData = () => {
  const [wishesData, setWishesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchWishes = async () => {
    try {
      const response = await axios.get('https://api.vinhnhatwedding.com/api/v1/wishes');
      setWishesData(response.data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWishes(); // Fetch data initially

    const interval = setInterval(() => {
      fetchWishes(); // Fetch data at every POLL_INTERVAL
    }, POLL_INTERVAL);

    return () => clearInterval(interval); // Clear interval on component unmount
  }, []);

  return { wishesData, loading, error };
};

export default useWishesData;
