import { css } from '@emotion/core';
import Background from '@assets/images/bg-wedding.jpg';

export const styWrapper = css`
  background-image: url('https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/templates/6174eeb9b9d68813734ce3f5/164846636c1ef456ed5e8dbf617a261f.jpg');
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 80vh;
  @media screen and (min-width: 360px) and (max-width: 1023px) {
    background-image: url('https://storage.googleapis.com/vinhnhatwedding/thumbnail/ALBUM_5.jpeg');
    background-size: cover !important;
    background-position: 25% 25% !important;
  }
  // @media screen and (min-width: 400px) {
  //   background-position: 50% 25%;
  // }
  color: #fff !important;
  .button-map {
    width: 100%;
    border-radius: 50px;
    border: none;
    height: 40px;
    margin-top: 10px;
    background: rgba(0, 0, 0, 0.3);
  }
  .button-map:hover {
    background: rgba(0, 0, 0, 0.5);
  }
  .button-map--link {
    display: block;
    width: 100%;
    color: rgb(255, 255, 255);
  }
  .event {
    width: 100%;
  }

  .location {
    width: 88%;
    text-align: left;
    margin-left: 8px;
    color: rgba(255, 255, 255, 0.8);
  }
  .bismillah {
    color: #fff !important;
    font-size: 16px !important;
    font-family: sans-serif;
  }

  .sub-title {
    color: #fff !important;
    font-size: 16px;
    font-family: 'Work Sans', Arial, sans-serif;
    font-weight: 400 !important;

    @media screen and (max-width: 400px) {
      font-size: 15px !important;
    }
  }

  .text__live {
    text-align: center;
    margin-top: 24px;
  }

  .main-font {
    margin-top: 12px;

    @media screen and (max-width: 400px) {
      line-height: 1;
      margin-bottom: 8px;
    }
  }
`;

export const styButtonWrapper = css`
  display: flex;
  justify-content: center;
  align-items: center;
  animation: pulse 2s ease infinite;
  margin-top: -8px;
  border: none;

  .img__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 500px;
    border-radius: 24px;
    background: #f14e95;
    padding: 8px 16px;
  }

  i {
    margin-right: 4px;
  }

  img {
    max-width: 28px;
    margin-right: 8px;
    margin-bottom: 0;
  }
`;
