import Nita from '@assets/images/w-nita.jpg';
import Zakiy from '@assets/images/w-zakiy.jpg';
import Moletta from '@assets/images/w-moletta.jpg';
import hapsari from '@assets/images/w-hapsari.jpg';
import dianara from '@assets/images/w-dianara.jpg';
import rahma from '@assets/images/w-rahma.jpg';
import vela from '@assets/images/w-vela.jpg';
import iqmala from '@assets/images/w-iqmala.jpg';
import man from './assets/user.png';
import ihsan from './assets/w-ihsan.jpg';
import fajar from './assets/w-fajar.jpg';
import risa from './assets/w-risa.jpg';
import puspita from './assets/w-puspita.jpg';
import kamal from './assets/w-kamal.jpg';
import ghea from './assets/w-ghea.jpg';
import emsho from './assets/w-emsho.jpg';
import alvia from './assets/w-alvia.jpg';
import yunias from './assets/w-yunias.jpg';
import yunita from './assets/w-yunita.jpg';
import fitria from './assets/w-fitria.jpg';
import joshua from './assets/w-joshua.jpg';
import sibra from './assets/w-sibra.jpg';
import irfan from './assets/w-irfan-m.png';
import reza from './assets/w-reza.jpg';
import jenny from './assets/w-jenny.jpg';

export const wishlist = [
  {
    name: 'Anh Dự',
    description: `"Chúc mừng anh trai của em đã có người rước nhé. Em mong anh chị có cuộc sống vui vẻ, hạnh phúc phía trước, làm ăn phát tài phát lộc."`,
    image: man,
  },
  {
    name: 'Thùy Linh',
    description: `"Chúc hai bạn ngày vui hạnh phúc. Hãy yêu thương nhau thật nhiều và sống thật hạnh phúc nha!"`,
    image: man,
  },
  {
    name: 'Bích Ngọc',
    description: `"Anh chị là một cặp trời sinh, chắc chắn sau này sẽ rất hạnh phúc. Em chúc anh chị sức khỏe dồi dào, làm ăn phát đạt và sớm có thiên thần nhỏ cho vui cửa vui nhà."`,
    image: man,
  },
  {
    name: 'Xuân Võ',
    description: `"Hôm nay là ngày vui của anh chị và cũng là ngày em rất hạnh phúc. Chúc anh chị mãi yêu thương nhau như bây giờ và đạt được mọi ước nguyện trong cuộc sống."`,
    image: man,
  },
  {
    name: 'Vân Anh',
    description: `"Chúc mừng hai bạn nhân ngày cưới! Đây là một dịp đặc biệt, đánh dấu sự khởi đầu của một hành trình mới đầy hạnh phúc và yêu thương. Mong rằng tình yêu của hai bạn sẽ ngày càng sâu đậm, vượt qua mọi thử thách và khó khăn trong cuộc sống. Hãy luôn trân trọng những khoảnh khắc bên nhau, chia sẻ niềm vui cũng như nỗi buồn, và cùng nhau xây dựng một gia đình ấm áp, hạnh phúc. 😍🥰😘"`,
    image: man,
  },
  {
    name: 'Sỹ Cường',
    description: `"Chúc mừng đôi bạn trẻ nhé! Mãi yêu thương nhau đến đầu bạc răng long bạn nha!"`,
    image: man,
  },
];
