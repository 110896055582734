import React, { Fragment } from 'react';
import { bool } from 'prop-types';

import WeddingInfoBox from './WeddingInfoBox';
import { styWrapper } from './styles';

function WeddingSection({ isInvitation }) {
  const renderGuestInfo = () => {
    return (
      <Fragment>
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <WeddingInfoBox
                title="LỄ VU QUY"
                time="08:00"
                date="Chủ Nhật, 07 Tháng 07 2024"
                location="Cam Nghĩa, Cam Ranh, Khánh Hòa"
                map="https://maps.app.goo.gl/HmijNiFB6bXqoYZP6"
              />
            </div>
            <div className="col-md-4">
              <WeddingInfoBox
                title="LỄ TÂN HÔN"
                time="08:00"
                date="Thứ Bảy, 13 Tháng 07 2024"
                location="Cam Phúc Bắc, Cam Ranh, Khánh Hòa"
                map="https://maps.app.goo.gl/zm8qcqqktc32iZuBA"
              />
            </div>
            <div className="col-md-4">
              <WeddingInfoBox
                title="LỄ BÁO HỶ"
                time="11:00"
                date="Chủ Nhật, 21 Tháng 07 2024"
                location="Maison De Charme - Quận 7, Hồ Chí Minh"
                map="https://maps.app.goo.gl/5pNCS4DB2AXaBJzA7"
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <div id="fh5co-event" css={styWrapper}>
        <div className="overlay" />
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
              <span className="bismillah">Wedding event</span>
              <h2 className="main-font main-font__wedding">Time and location of the event</h2>
              <span className="sub-title sub-title__wedding">Detailed event schedule:</span>
            </div>
          </div>
          <div className="row">
            {!isInvitation && renderGuestInfo()}
            {isInvitation && (
              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                    <WeddingInfoBox
                      title="LỄ VU QUY"
                      time="08:00"
                      date="Chủ Nhật 07 Tháng 10 2024"
                      location="Cam Nghĩa, Cam Ranh, Khánh Hòa"
                      map="https://maps.app.goo.gl/HmijNiFB6bXqoYZP6"
                    />
                  </div>
                  <div className="col-md-4">
                    <WeddingInfoBox
                      title="LỄ TÂN HÔN"
                      time="08:00"
                      date="Thứ Bảy 13 Tháng 10 2024"
                      location="Cam Phúc Bắc, Cam Ranh, Khánh Hòa"
                      map="https://maps.app.goo.gl/zm8qcqqktc32iZuBA"
                    />
                  </div>
                  <div className="col-md-4">
                    <WeddingInfoBox
                      title="LỄ BÁO HỶ"
                      time="11:00"
                      date="Chủ Nhật 21 Tháng 10 2024"
                      location="Maison De Charme - Restaurant & Events"
                      map="https://maps.app.goo.gl/5pNCS4DB2AXaBJzA7"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

WeddingSection.propTypes = {
  isInvitation: bool.isRequired,
};

export default React.memo(WeddingSection);
