import React, { Fragment } from 'react';
import { bool } from 'prop-types';

import { styWrapper } from './styles';

function HelloSection({ isInvitation }) {
  const finalSubtitle = '21/07/2024';

  return (
    <Fragment>
      <div id="fh5co-couple" css={styWrapper}>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
              <h2 className="main-font">We are Getting Married</h2>
              <h3 className="sub-title hs">{finalSubtitle}</h3>
              <p className="info">
                Thật hạnh phúc và ý nghĩa khi được cùng bạn chứng kiến sự kiện trọng đại này cùng tụi mình.
              </p>
            </div>
          </div>
          <div className="couple-wrap">
            <div className="couple-half couple-description--left">
              <div className="groom">
                <img
                  src="https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/65230d27e2fb8c6ff96a4ce115ad2096.jpeg"
                  alt="groom"
                  className="img-responsive"
                  loading="lazy"
                />
              </div>
              <div className="desc-groom">
                <h3 className="name-font">Vinh Huynh</h3>
                {<p className="parent-name parent-name__top parent-name__left">
                  Mình - một chàng IT gõ phím lách cách mỗi ngày. Tâm hồn hướng ngoại, thích khám phá và trải nghiệm, và
                  là tuýp người của gia đình. Luôn khao khát được làm lữ khách xa lạ trên những vùng đất mới, ngồi làm
                  một công việc mình đam mê, bên cạnh người mình yêu thương. Như vậy là hạnh phúc ♥️
                </p>}
              </div>
            </div>
            <p className="heart text-center">
              <i className="icon-heart2"></i>
              {/* <i class="fa-solid fa-heart"></i> */}
            </p>
            <div className="and-love">
              <i>&</i>
            </div>
            <div className="couple-half couple-description--right">
              <div className="bride">
                <img
                  src="https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/3165128b8c8b032c5458079e5574eb04.jpeg"
                  alt="groom"
                  className="img-responsive"
                  loading="lazy"
                />
              </div>
              <div className="desc-bride">
                <h3 className="name-font">Nhat Nguyen</h3>
                {<p className="parent-name parent-name__top parent-name__right">
                  Mình - một cô nàng lớn lên và làm việc ở vùng biển Cam Ranh đầy nắng và gió. Là một người hay cười
                  nhưng hướng nội, yêu thiên nhiên. Có niềm đam mê với ẩm thực và đặt chân đến vùng đất mới.
                  Hiện tại, mình đang vận hành kinh doanh cho riêng mình ✌️
                </p>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

HelloSection.propTypes = {
  isInvitation: bool.isRequired,
};

export default HelloSection;
