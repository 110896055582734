export const stories = [{
  title: 'First We Meet',
  date: 'January 2009',
  description: 'Hai đứa mình chơi thân từ lớp bồi dưỡng Lý cấp Tỉnh lớp 8, cùng lớp học thêm Toán thầy Tiến, và rồi học chung 3 năm cấp 3.<br/ >Cô nàng Thủ quỹ đã thương thầm anh chàng Lớp trưởng lớp 12 năm ấy ☺️<br />Và rồi cuộc sống cho cả hai được trải nghiệm cuộc sống tự do của mỗi người hẳn 10 năm ^.^',
  image: 'https://storage.googleapis.com/vinhnhatwedding/story/image_1.jpg',
}, {
  title: 'The First Trip Together',
  date: '27 June 2019',
  image: 'https://storage.googleapis.com/vinhnhatwedding/story/image_2.jpg',
  description: 'Trong 1 chuyến đi phượt Tây Bắc tại Hà Giang, cả 2 gặp lại nhau trong một hoàn cảnh chưa từng nghĩ đến, sau hơn 4 năm không liên lạc.<br />Chuyến đi này là bước ngoặt quan trọng trong mối quan hệ của chúng mình. Và sau hành trình, cả 2 nhận ra chúng mình đã có tình cảm đặc biệt 🙌',
}, {
  title: 'In A Relationship',
  date: '01 January 2022',
  description: 'Chúng mình xác định bắt đầu một mối quan hệ nghiêm túc trong một ngày mưa khi cùng nhau chạy bộ đón năm mới.<br />Hứa hẹn từ giờ sẽ là một hành trình dài đầy ắp yêu thương và hạnh phúc. Cùng nhau viết tiếp câu chuyện tình yêu của mình, vượt qua mọi thử thách, đặc biệt là yêu xa ^.^',
  image: 'https://storage.googleapis.com/vinhnhatwedding/story/image_3.jpeg',
}, {
  title: 'Proposal Day',
  date: '10 December 2023',
  description: 'Và ngày anh bước qua tuổi 28, cũng là lúc anh dành một món quà bất ngờ cho em - Lời cầu hôn mà em chưa nghĩ đến.<br> Cảm ơn anh, hai năm rưỡi qua đã nỗ lực yêu em<br />Sự rung động từ trái tim, chính là mật mã của hạnh phúc.<br /> Mãi bên nhau dài lâu anh nhé ♥️',
  image: 'https://storage.googleapis.com/vinhnhatwedding/story/image_5.jpeg',
}];
