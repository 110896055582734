import { useState, useEffect, useCallback } from 'react';

const INTERVAL_TIME = 1000;
const DAY_PER_SECONDS = 86400;
const HOUR_PER_SECONDS = 3600;
const MINUTE_PER_SECONDS = 60;

const getEpochTime = () => Math.floor(new Date().getTime() / 1000);
const VN_TIME_OFFSET = 7 * 60 * 60; // UTC+7 for Vietnam

function useDateCountdown() {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [timeHasRunOut, setTimeHasRunOut] = useState(false);
  const [isEventOver, setIsEventOver] = useState(false);

  const calculateTimeLeft = useCallback(() => {
    const currentEpoch = getEpochTime();
    const eventStartEpoch = 1721534400; // Thời gian bắt đầu sự kiện tính theo múi giờ UTC
    // const eventEndEpoch = 1721494740; // Thời gian kết thúc sự kiện tính theo múi giờ UTC
    const timeLeft = eventStartEpoch - currentEpoch;

    // if (timeLeft < 0) {
    //   setIsEventOver(currentEpoch > eventEndEpoch);
    //   setTimeHasRunOut(true);
    //   return;
    // }
    // const timeLeftInVietnam = eventStartEpoch - vietnamTime;

    const daysLeft = Math.floor(timeLeft / DAY_PER_SECONDS);
    const hoursLeft = Math.floor((timeLeft % DAY_PER_SECONDS) / HOUR_PER_SECONDS);
    const minutesLeft = Math.floor(((timeLeft % DAY_PER_SECONDS) % HOUR_PER_SECONDS) / MINUTE_PER_SECONDS);
    const secondsLeft = Math.floor(((timeLeft % DAY_PER_SECONDS) % HOUR_PER_SECONDS) % MINUTE_PER_SECONDS);

    // const daysLeft = Math.floor(timeLeftInVietnam / DAY_PER_SECONDS);
    // const hoursLeft = Math.floor((timeLeftInVietnam % DAY_PER_SECONDS) / HOUR_PER_SECONDS);
    // const minutesLeft = Math.floor(((timeLeftInVietnam % DAY_PER_SECONDS) % HOUR_PER_SECONDS) / MINUTE_PER_SECONDS);
    // const secondsLeft = Math.floor(((timeLeftInVietnam % DAY_PER_SECONDS) % HOUR_PER_SECONDS) % MINUTE_PER_SECONDS);

    setDays(daysLeft);
    setHours(hoursLeft);
    setMinutes(minutesLeft);
    setSeconds(secondsLeft);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      calculateTimeLeft();
    }, INTERVAL_TIME);

    return () => clearInterval(interval);
  }, [calculateTimeLeft]);

  return { days, hours, minutes, seconds, timeHasRunOut, isEventOver };
}

export default useDateCountdown;
